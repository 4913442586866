<template lang="pug">
  v-container
    v-toolbar(color='primary' dark elevation='0' class='mb-2')
      span New Supplier
      v-spacer
      v-icon mdi-account
      v-icon mdi-plus
    v-alert(
      type='success'
      dismissible
      v-if='splrPostSuccess'
    )
      span {{ splrPostSuccess }}
    v-alert(
      type='error'
      dismissible
      v-for='(item, index) in splrPostErrors'
      :key='index'
    )
      span.text-uppercase {{ item.first() }}
    - const inputAttrs = { ':outlined': 'true', ':dense': 'true' }
    v-row(class='px-2')
      v-col.pb-0(offset-lg='6' lg='3' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Supplier ID'
          :value='supplierId'
          readonly
        )
          template(#append)
            v-icon(
              v-if="!newSupplierIdGetting"
              color="primary"
              @click="getNewSupplierId"
            ) mdi-refresh
            v-progress-circular(
              v-else
              indeterminate
              color="primary"
              width="2"
              size="18"
              :value="true"
            )
      v-col.pb-0(lg='3' cols='12')
        v-autocomplete&attributes(inputAttrs)(
          label='Category'
          v-model='supplierCategoryId'
          :items='splrCatList'
          item-value='id'
          :loading='splrCatGetting'
        )
          template(v-slot:selection='{ item }')
            span.primary--text.mr-2 {{ item.id }}
            span.grey--text.caption {{ item.description }}
          template(v-slot:item='{ item }')
            span.primary--text.mr-2 {{ item.id }}
            span.grey--text.caption {{ item.description }}
      v-col.py-0(lg='4' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Company Name'
          v-model='companyName'
        )
      v-col.py-0(lg='4' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Bussiness Phone'
          v-model='businessPhone'
        )
      v-col.py-0(lg='4' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Fax Number'
          v-model='faxNumber'
        )
      v-col.py-0(lg='3' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Country'
          v-model='country'
        )
      v-col.py-0(lg='3' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Postal Code'
          v-model='postalCode'
        )
      v-col.py-0(lg='3' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='City'
          v-model='city'
        )
      v-col.py-0(lg='3' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Province'
          v-model='province'
        )
      v-col.py-0(lg='3' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Region'
          v-model='region'
        )
      v-col.py-0(lg='9' cols='12')
        v-text-field&attributes(inputAttrs)(
          label='Address'
          v-model='address'
        )
      v-col.py-0(cols='12')
        v-textarea(
          label='Notes'
          v-model='notes'
          auto-grow
          clearable
          outlined
          rows='5'
          no-resize
        )
      v-col.py-0(lg='3' cols='12')
        v-btn(
          color='primary'
          @click='storeSupplier'
          :loading='splrPosting'
        )
          span Save
</template>
<script>
import splrRepository from '@/repositories/supplier.repository'
import { postVars, getVars, errorHandler, requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import searchDelay from '@/libs/searchDelay.extra'
import splrCatRepository from '@/repositories/supplier-category.repository'

const splrStoreDelay = searchDelay()
const splrCatStoreDelay = searchDelay()

const inputVars = () => ({
  supplierId: null,
  supplierCategoryId: null,
  companyName: null,
  businessPhone: null,
  faxNumber: null,
  address: null,
  city: null,
  province: null,
  region: null,
  postalCode: null,
  country: 'Philippines',
  notes: null,
})

const [newSupplierIdVars, newSupplierIdVarNames] = requestVars({ identifier: 'new-supplier-id', hasData: false })

const newSupplierIdHandler = new VueRequestHandler(null, newSupplierIdVarNames)

export default {
  name: 'CreateSupplier',
  created () {
    this.getSupplierCategory()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...postVars('splr', { success: null }),
      ...getVars('splr-cat'),
      ...newSupplierIdVars,
    }
  },
  watch: {
    supplierCategoryId () {
      this.getNewSupplierId()
    },
  },
  methods: {
    getSupplierCategory () {
      if (this.splrCatGetting) return
      this.splrCatGetting = true
      this.splrCatGetErrors = []
      splrCatStoreDelay(() => {
        splrCatRepository.index()
          .then(({ data }) => { this.splrCatList = data })
          .catch(e => { this.splrCatGetErrors = [] })
          .then(() => { this.splrCatGetting = false })
      })
    },
    storeSupplier () {
      if (this.splrPosting) return
      this.splrPosting = true
      this.splrPostErrors = []
      splrStoreDelay(() => {
        const data = this.getInputVars()
        splrRepository.store(data)
          .then(this.splrStoreSuccess)
          .catch(e => { this.splrPostErrors = errorHandler(e) })
          .then(() => { this.splrPosting = false })
      })
    },
    async getNewSupplierId () {
      if (!this.supplierCategoryId) return
      const handler = newSupplierIdHandler
      const repository = splrRepository.newSupplierId
      handler.setVue(this)
      const { data } = await handler.execute(repository, [this.supplierCategoryId])
      this.supplierId = data
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.channel('database.updated')
        .listen('SupplierCategoryUpdatedEvent', e => { this.getSupplierCategory() })
    },
    splrStoreSuccess () {
      const { supplierId, companyName } = this
      this.splrPostSuccess = `The Supplier ${supplierId}: ${companyName} has been stored successfully`
      this.getNewSupplierId()
      {
        const { supplierId, supplierCategoryId, ...data } = inputVars()
        this.reset(data)
      }
    },
    reset (vars) {
      Object.keys(vars).forEach(e => { this[e] = vars[e] })
    },
    setSupplierId () {
      const date = new Date()
      this.supplierId = [
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ].join('')
    },
    getInputVars () {
      const data = { id: this.supplierId }
      Object.keys(inputVars()).forEach(e => {
        if (e === 'supplierId') return
        data[e.camelToSnakeCase()] = this[e]
      })
      return data
    },
  },
}
</script>
