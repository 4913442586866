import repository from './repository'

const resource = 'suppliers'

export default {
  index () {
    return repository.get(resource)
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  newSupplierId (category) {
    return repository.get(`${resource}/${category}/new-supplier-id`)
  },
}
